import type { PaymentMethod as CLPaymentMethod } from '@commercelayer/sdk';
import {
  createClient,
  type ContentfulClientApi,
  type CreateClientParams,
  type Entry,
  type EntryCollection,
} from 'contentful';

import type { FailureKind } from 'shared/components/Failure/Failure';
import type { Channel } from 'shared/constants/Channel';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type {
  CONTENT_TYPE,
  IBankDetailsFields,
  ICatalogFields,
  ICountryFields,
  IExperimentalTaxNoticesFields,
  IMarketFields,
  INationalIdFormFields,
  IUiFailurePageFields,
  IUiNotFoundPageFields,
  LOCALE_CODE,
} from 'shared/infra/contentful/contentful';
import type {
  NationalIdType,
  ParsedNationalIdForm,
  PaymentMethodContent,
} from 'shared/infra/contentful/types';
import { forceImgSrcToHttps } from 'shared/services/imageUrl';
import logger from 'shared/services/logger';
import type { Maybe } from 'types/util';

import { previewInfo } from './preview';

interface ContentfulQuery {
  locale?: LOCALE_CODE;
  include?: number;
  content_type?: CONTENT_TYPE;
  links_to_entry?: string;
  limit?: number;
  select?: string;
  skip?: number;
  order?: string;
  // for query nested fields (e.g. "fields.name[ne]")
  [key: string]: unknown;
}

export enum ContentfulSearchDepth {
  FIRST_LEVEL = 1,
  SECOND_LEVEL = 2,
  THIRD_LEVEL = 3,
  FOURTH_LEVEL = 4,
}

type FilterCatalogProps = Pick<ICatalogFields, 'channel'> &
  Pick<IMarketFields, 'countryCode'> & {
    locale: LOCALE_CODE;
    searchDepth?: ContentfulSearchDepth;
  };

export type BankDetailsWithCode = {
  bankWirePaymentDetails?: IBankDetailsFields;
  marketCode: string;
};

export interface IContentfulClientApi extends ContentfulClientApi {
  getEntries<T>(query?: ContentfulQuery): Promise<EntryCollection<T>>;
}

const PREVIEW_URL = 'preview.contentful.com';
const DELIVERY_URL = 'cdn.contentful.com';

export const getClient = (): IContentfulClientApi => {
  const isPreview = previewInfo().getPreviewMode();
  const host = isPreview ? PREVIEW_URL : DELIVERY_URL;
  const accessToken = isPreview
    ? process.env.CONTENTFUL_PREVIEW_TOKEN
    : process.env.CONTENTFUL_ACCESS_TOKEN;

  const clientConfig: CreateClientParams = {
    accessToken,
    host,
    space: process.env.CONTENTFUL_SPACE,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
  };

  const client = createClient(clientConfig);

  return client;
};

export const getCatalogEntriesForCountryAndChannel = ({
  countryCode,
  channel,
  locale,
  searchDepth = ContentfulSearchDepth.FOURTH_LEVEL,
}: FilterCatalogProps): Promise<EntryCollection<ICatalogFields>> => {
  const client = getClient();

  return client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'fields.channel': channel,
    'include': searchDepth,
    locale,
  });
};

export const getCatalogForCountryAndChannel = async (
  filterCatalogProps: FilterCatalogProps,
): Promise<ICatalogFields> => {
  const catalogEntries = await getCatalogEntriesForCountryAndChannel(
    filterCatalogProps,
  );

  if (catalogEntries.items.length !== 1) {
    const err = new Error(
      `Catalog entries don't exist or are misconfigured, expected 1 entry, but got ${catalogEntries.items.length}`,
    );

    logger
      .withContext({
        tags: {
          metricName: 'misconfigured_catalog_entries',
          countryCode: filterCatalogProps.countryCode,
          channel: filterCatalogProps.channel,
        },
      })
      .error(err);

    // not sure about this but it maintains the current behavior
    if (!catalogEntries.items.length) {
      return undefined;
    }
  }

  return catalogEntries.items[0].fields;
};

export const getCatalogEntriesByCountry = (
  countryCode: string,
  locale: LOCALE_CODE,
  searchDepth = ContentfulSearchDepth.FOURTH_LEVEL,
): Promise<EntryCollection<ICatalogFields>> => {
  const client = getClient();

  return client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'include': searchDepth,
    locale,
  });
};

export const getAvailableChannelsByCountry = async (
  countryCode: string,
  searchDepth = ContentfulSearchDepth.SECOND_LEVEL,
): Promise<Channel[]> => {
  const client = getClient();

  const catalogEntries = await client.getEntries<ICatalogFields>({
    'content_type': 'catalog',
    'fields.market.sys.contentType.sys.id': 'market',
    'fields.market.fields.countryCode': countryCode,
    'include': searchDepth,
  });

  const channels = (catalogEntries.items ?? []).map(
    (catalog: Entry<ICatalogFields>) => catalog.fields?.channel,
  );

  return channels;
};

export const getMarketEntriesByCountryCode = (
  countryCode: string,
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IMarketFields>> => {
  const client = getClient();

  return client.getEntries<IMarketFields>({
    'content_type': 'market',
    'fields.countryCode': countryCode,
    'locale': locale,
    'include': 3,
  });
};

export const getUI404PageEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiNotFoundPageFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'uiNotFoundPage',
    locale,
  });
};

export const getFailurePagesByKind = (
  kind: FailureKind,
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IUiFailurePageFields>> => {
  const client = getClient();

  return client.getEntries({
    'content_type': 'uiFailurePage',
    locale,
    'fields.kind': kind,
  });
};

export const getCountriesWithLocales = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<ICountryFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'country',
    locale,
  });
};

export const getPaymentMethodsForMarket = (
  market: Entry<IMarketFields>,
): PaymentMethodContent[] => {
  // like { bankwire - id12345 }
  const clPaymentMethods = market.fields.commerceLayerPaymentMethods as Record<
    PaymentMethod,
    string
  >;

  const cfPaymentMethods = market.fields.paymentMethods;

  const output = (cfPaymentMethods || []).map<PaymentMethodContent>(
    (cfPaymentMethod) => {
      const paymentMethodId =
        clPaymentMethods[cfPaymentMethod.fields.commerceLayerPaymentType];

      const iconUrl = cfPaymentMethod.fields.icon?.fields?.file?.url;
      return {
        id: paymentMethodId || null, // Allow null ID to be replaced by CL ID later
        method: cfPaymentMethod.fields.commerceLayerPaymentType,
        label: cfPaymentMethod.fields.title,
        message: cfPaymentMethod.fields.paymentMethodMessage || '',
        icon: {
          url: iconUrl ? forceImgSrcToHttps(iconUrl) : null,
          height:
            cfPaymentMethod.fields.icon?.fields?.file?.details?.image?.height ||
            24,
          width:
            cfPaymentMethod.fields.icon?.fields?.file?.details?.image?.width ||
            24,
          name: cfPaymentMethod.fields.icon?.fields?.title || '',
        },
        isAlternativePaymentMethod:
          cfPaymentMethod.fields.isAlternativePaymentMethod,
        isSubscriptionSupported: Boolean(
          cfPaymentMethod.fields.isSubscriptionSupported,
        ),
        isPaymentOnFileSupported:
          cfPaymentMethod.fields.isPaymentOnFileSupported,
        termsAndConditions: cfPaymentMethod.fields.termsAndConditions || null,
      };
    },
  );

  return output;
};

export const enrichPaymentMethodsWithIds = (
  market: Entry<IMarketFields>,
  clPaymentMethods: CLPaymentMethod[],
): PaymentMethodContent[] => {
  const cfPaymentMethods = getPaymentMethodsForMarket(market);

  if (clPaymentMethods.length === 0) {
    return cfPaymentMethods;
  }

  const clPMReferenceToId = clPaymentMethods.reduce<Record<string, string>>(
    (acc, pm) => ({ ...acc, [pm.reference]: pm.id }),
    {},
  );

  return cfPaymentMethods.map((cfPM) => {
    const clId = clPMReferenceToId[cfPM.method];

    if (cfPM.id && clId && clId !== cfPM.id) {
      logger
        .withContext({
          tags: {
            metricName: 'cl_payment_method_id_mismatch' as const,
            paymentMethodReference: cfPM.method,
          },
          extra: {
            paymentMethodId: cfPM.id,
          },
        })
        .warn(
          `Payment method ID mismatch between CommerceLayer (${clId}) and Contentful (${cfPM.id})`,
        );
    }

    return {
      ...cfPM,
      id: clId || cfPM.id,
    };
  });
};

export const parseNationalIdFormFields = (
  nationalIdFormContent: INationalIdFormFields,
): ParsedNationalIdForm => {
  if (
    !nationalIdFormContent ||
    !nationalIdFormContent.acceptedNationalIdsBusiness ||
    !nationalIdFormContent.acceptedNationalIdsPersonal
  ) {
    return null;
  }

  const acceptedNationalIdsPersonal =
    nationalIdFormContent?.acceptedNationalIdsPersonal
      ? nationalIdFormContent?.acceptedNationalIdsPersonal.map(
          (nationalId) => ({
            ...nationalId.fields,
          }),
        )
      : [];

  const acceptedNationalIdsBusiness =
    nationalIdFormContent?.acceptedNationalIdsBusiness
      ? nationalIdFormContent?.acceptedNationalIdsBusiness.map(
          (nationalId) => ({
            ...nationalId.fields,
          }),
        )
      : [];

  return {
    ...nationalIdFormContent,
    acceptedNationalIdsPersonal: acceptedNationalIdsPersonal.map(
      (acceptedNationalId) => ({
        ...acceptedNationalId,
        type: acceptedNationalId.type as NationalIdType,
        inputField: acceptedNationalId.inputField.fields,
      }),
    ),
    acceptedNationalIdsBusiness: acceptedNationalIdsBusiness.map(
      (acceptedNationalId) => ({
        ...acceptedNationalId,
        type: acceptedNationalId.type as NationalIdType,
        inputField: acceptedNationalId.inputField.fields,
      }),
    ),

    nationalIdPersonalLabel: nationalIdFormContent?.nationalIdPersonalLabel,
    nationalIdBusinessLabel: nationalIdFormContent?.nationalIdBusinessLabel,
    selectNationalIdLabel: nationalIdFormContent?.selectNationalIdLabel || '',
  };
};

export const getNationalIdContentFieldsFromMarket = (
  market: Entry<IMarketFields>,
): ParsedNationalIdForm => {
  const marketFields = market?.fields;
  const nationalIdFormContent = marketFields?.nationalIdForm?.fields;

  return parseNationalIdFormFields(nationalIdFormContent);
};

export const getExperimentalTaxNoticesEntries = (
  locale: LOCALE_CODE = 'intl',
): Promise<EntryCollection<IExperimentalTaxNoticesFields>> => {
  const client = getClient();

  return client.getEntries({
    content_type: 'experimentalTaxNotices',
    locale,
  });
};

export const getBankDetailsWithMarketCode = async (
  locale: LOCALE_CODE,
  channel: Channel,
): Promise<Maybe<BankDetailsWithCode>> => {
  const catalog = await getCatalogForCountryAndChannel({
    countryCode: getCountryCodeFromISOLocale(locale),
    locale,
    channel,
  });

  if (!catalog) {
    return null;
  }

  const bankWirePaymentDetails =
    catalog.uiThankYouPage.fields.paymentDetails.find(
      (item) => item.fields.name === 'bankwire',
    ).fields;

  return {
    bankWirePaymentDetails,
    marketCode: catalog.market.fields.countryCode,
  };
};
